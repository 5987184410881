import { reactive, ref } from 'vue';
import ApiClient from '@/services/api';
import Notes from '@/components/ui/notes/Notes.vue';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';

import showToast from '@/libs/toasts';

import {
  AddSuccess,
  AddError,
  EditSuccess,
  EditError,
  DeleteSuccess,
  DeleteError,
} from '@feedback/component/notes';

// notes component
export const NotesComponent = Notes;

/**
 * Main hook function
 * Responsible for storing current employee's notes
 * + creating and editing
 *
 * TODO: refactor add+edit methods
 */
export const useNotesComponent = (items, targetId, {
  createUrl = 'api/cadre/notes',
  editUrl = 'api/cadre/notes/:id',
  targetKey = 'employee_id',
} = {}) => {
  const urls = {
    create: createUrl,
    edit: editUrl,
  };

  // reference with new/edited note content
  const note = ref({});

  /**
   * Reactive notes list
   * serves as a store for the component
   */
  const notes = reactive(items);

  // basic refs serving as v-model for modals
  // (true = modal shown)
  const isAddModalShown = ref(false);
  const isEditModalShown = ref(false);

  const openAddModal = () => {
    isAddModalShown.value = true;
  };

  const openEditModal = (value = null) => {
    note.value = { ...value } ?? {};
    isEditModalShown.value = true;
  };

  const closeAddModal = () => {
    isAddModalShown.value = false;
  };

  const closeEditModal = () => {
    isEditModalShown.value = false;
  };

  /**
   * Add a new note
   */
  const add = (modalEvent) => {
    modalEvent.preventDefault();

    if (!note.value) return null;

    const object = {
      ...note.value,
    };

    object[targetKey] = targetId;

    const request = ApiClient.post(urls.create, object);

    request
      .then(({ data }) => {
        showToast(AddSuccess);
        notes.push({ ...data?.data });
        note.value = {};
        closeAddModal();
      })
      .catch(() => {
        showToast(AddError);
      });

    return request;
  };

  /**
   * Edit existing note
   */
  const edit = (modalEvent) => {
    modalEvent.preventDefault();
    if (!note.value.id && note.value.id !== 0) return null;

    const url = urls.edit.replace(':id', note.value.id);
    const request = ApiClient.put(url, note.value);

    request
      .then(({ data }) => {
        showToast(EditSuccess);

        const idx = notes.findIndex((n) => n.id === data.data.id);
        notes[idx] = data.data;

        note.value = {};
        closeEditModal();
      })
      .catch(() => {
        showToast(EditError);
      });

    return request;
  };

  const remove = async (id) => {
    const confirmation = await deleteModal({
      title: i18n.t('component.notes.modal.delete.title'),
      text: i18n.t('component.notes.modal.delete.text', {
        name: notes.find((n) => n.id === id)?.title,
      }),
    });

    if (!confirmation) return;

    const request = ApiClient.delete(urls.edit.replace(':id', id));

    request
      .then(() => {
        showToast(DeleteSuccess);

        const idx = notes.findIndex((n) => n.id === id);
        notes.splice(idx, 1);
      })
      .catch(() => {
        showToast(DeleteError);
      });
  };

  return {
    notes,
    note,

    add,
    edit,
    remove,

    isAddModalShown,
    isEditModalShown,

    openAddModal,
    openEditModal,
    closeAddModal,
    closeEditModal,
  };
};

export default useNotesComponent;
