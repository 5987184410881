var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header-section', {
    attrs: {
      "title": _vm.$t('component.notes.name'),
      "action": _vm.$t('component.notes.button.add')
    },
    on: {
      "actionClick": _vm.openAddModal
    }
  }, [_c('app-collapse', {
    attrs: {
      "accordion": ""
    }
  }, _vm._l(_vm.notes, function (note) {
    return _c('note-item', {
      key: note.id,
      attrs: {
        "note": note
      },
      on: {
        "remove": _vm.remove,
        "edit": _vm.openEditModal
      }
    });
  }), 1), _c('notes-add-modal', {
    attrs: {
      "showModal": _vm.isAddModalShown
    },
    on: {
      "hide": _vm.closeAddModal,
      "add": _vm.add
    },
    model: {
      value: _vm.note,
      callback: function ($$v) {
        _vm.note = $$v;
      },
      expression: "note"
    }
  }), _c('notes-edit-modal', {
    attrs: {
      "showModal": _vm.isEditModalShown
    },
    on: {
      "hide": _vm.closeEditModal,
      "edit": _vm.edit
    },
    model: {
      value: _vm.note,
      callback: function ($$v) {
        _vm.note = $$v;
      },
      expression: "note"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }