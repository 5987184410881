import ComponentFeedback from '@feedback/component/ComponentFeedback';

/**
 * Defined Feedback objects.
 *
 * Currently it looks messy, but the whole point of
 * defining each error separately (instead of using plain i18n)
 * is to allow further changes to the Feedback structure, e.g.
 * types, actions, custom templates and more settings.
 *
 * TODO: break this down into separate files whenever the Feedback
 *       logic will be ready
 *
 * After adding a feedback instance below, remember to add the proper
 * key to i18n
 */

/** Negative Feedbacks */
export const AddError = new ComponentFeedback('AddError', false);
export const EditError = new ComponentFeedback('EditError', false);
export const DeleteError = new ComponentFeedback('DeleteError', false);

/** Positive Feedbacks */
export const AddSuccess = new ComponentFeedback('AddSuccess', true);
export const EditSuccess = new ComponentFeedback('EditSuccess', true);
export const DeleteSuccess = new ComponentFeedback('DeleteSuccess', true);
