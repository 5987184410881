<template>

  <header-section
    :title="$t('component.notes.name')"
    :action="$t('component.notes.button.add')"
    @actionClick="openAddModal"
    >
    <app-collapse accordion>
      <note-item
        v-for="note in notes"
        :key="note.id"
        :note="note"

        @remove="remove"
        @edit="openEditModal"
        />
    </app-collapse>

    <notes-add-modal
      v-model="note"
      :showModal="isAddModalShown"

      @hide="closeAddModal"
      @add="add"
    />

    <notes-edit-modal
      v-model="note"
      :showModal="isEditModalShown"

      @hide="closeEditModal"
      @edit="edit"
    />

  </header-section>

</template>

<script>
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue';
import NotesAddModal from '@/components/ui/notes/modals/NoteAdd.vue';
import NotesEditModal from '@/components/ui/notes/modals/NoteEdit.vue';
// eslint-disable-next-line import/no-cycle, import/no-named-as-default
import useNotesComponent from '@/hooks/components/useNotesComponent';
import NoteItem from '@/components/ui/notes/NoteItem.vue';
import HeaderSection from '@/components/ui/HeaderSection.vue';
import { useRouter } from '@/@core/utils/utils';

export default {
  props: {
    items: Array,
    createUrl: String,
    editUrl: String,
    targetKey: String,
    targetId: [String, Number],
  },
  setup(props) {
    const { route } = useRouter();
    const {
      note,
      notes,

      add,
      edit,
      remove,

      isAddModalShown,
      isEditModalShown,
      openAddModal,
      closeAddModal,

      openEditModal,
      closeEditModal,
    } = useNotesComponent(props.items, props.targetId ?? route.value.params?.id, {
      createUrl: props.createUrl ?? 'api/cadre/notes',
      editUrl: props.editUrl ?? 'api/cadre/notes/:id',
      targetKey: props.targetKey ?? 'employee_id',
    });

    return {
      note,
      notes,

      add,
      edit,
      remove,

      isAddModalShown,
      isEditModalShown,

      openAddModal,
      closeAddModal,
      openEditModal,
      closeEditModal,
    };
  },
  components: {
    HeaderSection,
    NoteItem,
    AppCollapse,
    NotesAddModal,
    NotesEditModal,
  },
};
</script>

<style lang="sass" scoped>
.comment-buttons
  display: flex
  justify-content: end
  margin-top: 15px
</style>
