<template>
  <div class="note-form">
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('component.notes.modal.field.title')"
            label-cols-md="3"
            :invalid-feedback="errors.title"
            :state="errors.title === null"
            >
            <b-input-group>
              <b-form-input
                :placeholder="$t('component.notes.modal.field.title')"
                v-model="task.title"/>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- wysiwyg na opis -->
        <b-col cols="12">
          <b-form-group
            :label="$t('component.notes.modal.field.description')"
            :invalid-feedback="errors.description"
            :state="errors.description === null"
            >
            <b-input-group class="input-group-merge">
              <editor
                v-model="task.description"/>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue';
import Editor from '@/components/ui/vs-wysiwyg/Editor.vue';
import { computed } from 'vue';

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const errors = {};

    const task = computed({
      get() {
        return props.value;
      },
      set(value) {
        context.emit('input', value);
      },
    });

    return { errors, task };
  },
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BInputGroup,
    BFormInput,

    Editor,
  },
};
</script>
