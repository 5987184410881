var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-collapse-item', {
    staticClass: "note-collapse",
    attrs: {
      "title": _vm.note.title
    }
  }, [_c('div', {
    staticClass: "note"
  }, [_c('div', {
    staticClass: "note-description",
    domProps: {
      "innerHTML": _vm._s(_vm.note.description)
    }
  }), _c('div', {
    staticClass: "note-footer"
  }, [_c('user-label', {
    attrs: {
      "id": _vm.note.author_id,
      "showLink": ""
    }
  }), _c('div', {
    staticClass: "date"
  }, [_vm._v("(" + _vm._s(_vm.date) + ")")]), _vm.note.author_id === _vm.user.id ? _c('div', {
    staticClass: "buttons"
  }, [_c('b-button', {
    staticClass: "btn-icon",
    attrs: {
      "variant": "flat-secondary"
    },
    on: {
      "click": _vm.edit
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "Edit2Icon",
      "size": "18"
    }
  })], 1), _c('b-button', {
    staticClass: "btn-icon",
    attrs: {
      "variant": "flat-secondary"
    },
    on: {
      "click": _vm.remove
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "Trash2Icon",
      "size": "18"
    }
  })], 1)], 1) : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }