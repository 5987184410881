var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-add-note"
  }, [_c('b-modal', {
    attrs: {
      "id": "notes-modal-add",
      "title": _vm.$t('component.notes.modal.title'),
      "cancel-variant": "outline-secondary",
      "cancel-title": _vm.$t('tasks.add.button.cancel'),
      "ok-title": _vm.$t('tasks.add.button.save'),
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": function (e) {
        return _vm.$emit('add', e);
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref) {
        var ok = _ref.ok,
          cancel = _ref.cancel;
        return [_c('vs-modal-footer', {
          attrs: {
            "is-loading": _vm.loading,
            "text-cancel": _vm.$t('tasks.add.button.cancel'),
            "text-ok": _vm.$t('component.notes.button.add')
          },
          on: {
            "cancel": cancel,
            "ok": ok
          }
        })];
      }
    }]),
    model: {
      value: _vm.modalShown,
      callback: function ($$v) {
        _vm.modalShown = $$v;
      },
      expression: "modalShown"
    }
  }, [_vm.loading ? _c('vs-loader', {
    attrs: {
      "full": "",
      "text": "Tworzenie notatki..."
    }
  }) : _vm._e(), _c('b-card-text', [_c('note-form', {
    model: {
      value: _vm.note,
      callback: function ($$v) {
        _vm.note = $$v;
      },
      expression: "note"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }