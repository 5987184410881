<template>
  <app-collapse-item
    class="note-collapse"
    :title="note.title"
    >
    <div class="note">
      <div class="note-description" v-html="note.description"></div>
      <div class="note-footer">
        <user-label :id="note.author_id" showLink />
        <div class="date">({{ date }})</div>
        <div class="buttons" v-if="note.author_id === user.id">
          <b-button
            class="btn-icon"
            variant="flat-secondary"
            @click="edit"
            >
            <feather-icon icon="Edit2Icon" size="18"></feather-icon>
          </b-button>
          <b-button
            class="btn-icon"
            variant="flat-secondary"
            @click="remove"
            >
            <feather-icon icon="Trash2Icon" size="18"></feather-icon>
          </b-button>
        </div>
      </div>
    </div>
  </app-collapse-item>
</template>

<script>
import { computed } from 'vue';
import { mapGetters } from 'vuex';
import formatDate from '@/libs/date-formatter';
import {
  BButton,
} from 'bootstrap-vue';
import UserLabel from '@/components/ui/UserLabel.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';

export default {
  components: {
    BButton,
    UserLabel,
    AppCollapseItem,
  },
  setup(props) {
    const date = computed(() => formatDate.noteCreated(props.note.created_at));

    return { date };
  },
  props: {
    note: Object,
  },
  methods: {
    remove() {
      this.$emit('remove', this.note.id);
    },
    edit() {
      this.$emit('edit', this.note);
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
};
</script>

<style lang="sass" scoped>
.note-collapse::v-deep
  .card-header,
  .card-body
    padding-left: 0
    padding-right: 0

.note
  &:not(:first-child)
    border-top: 1px solid rgba(black, 0.1)
    padding: 15px 0

.note-footer
  display: flex
  align-items: center

  .date
    margin-left: 5px
    opacity: 0.8

  .buttons
    margin-left: auto

</style>
