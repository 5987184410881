<template>
  <div class="modal-edit-note">
    <b-modal
      id="notes-modal-edit"
      :title="$t('component.notes.modal.title')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('tasks.edit.button.cancel')"
      :ok-title="$t('tasks.edit.button.save')"
      size="lg"
      v-model="modalShown"
      @ok="(e) => $emit('edit', e)"
      no-close-on-backdrop
      >
      <vs-loader full
        text="Aktualizowanie notatki..."
        v-if="loading"
      />

      <b-card-text>
        <note-form v-model="note"/>
      </b-card-text>

      <template #modal-footer="{ ok, cancel }">
        <vs-modal-footer
          :is-loading="loading"

          :text-cancel="$t('tasks.add.button.cancel')"
          :text-ok="$t('component.notes.button.update')"

          @cancel="cancel"
          @ok="ok"
        />
      </template>

    </b-modal>
  </div>
</template>

<script>
import { BCardText } from 'bootstrap-vue';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import NoteForm from '../NoteForm.vue';

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    note: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    modalShown: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit('input', {});
        this.$emit('hide');
      },
    },
  },
  setup() {
    const loading = false;

    return {
      loading,
    };
  },
  components: {
    BCardText,
    NoteForm,
    VsModalFooter,
    VsLoader,
  },
};
</script>
